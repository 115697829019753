const links = [
  {
    label: 'Blog',
    url: '/'
  },
  {
    label: 'Talks',
    url: '/talks/'
  },
  {
    label: 'Sobre',
    url: '/about/'
  },
];

export default links;
